<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.warehouseSubNav.spaceAvailabilityDashboard') }}</h4>
        </template>
      <template v-slot:body>
        <b-row v-if="authUser.warehouse_id == null">
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
            >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.division')"
                label-for="division_id"
            >
              <v-select name="division_id"
                    v-model="search.division_id"
                    label="text"
                    :options= divisionList
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
            </b-form-group>
          </b-col>
           <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
            >
              <v-select name="district_id"
                v-model="search.district_id"
                label="text"
                :options= districtList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
            >
              <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
            >
               <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
            <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                 v-if="!authUser.warehouse_id"
                />
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  :placeholder="$t('globalTrans.select')"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="authUser.warehouse_id == null" xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_service.crop_type')"
                label-for="crop_type"
            ><v-select name="crop_type_id"
                v-model="search.crop_type_id"
                label="text"
                :options= cropTypeList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
         <b-col v-if="authUser.warehouse_id == null" xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_service.crop_name')"
                label-for="crop_name"
            >
                <v-select name="crop_id"
                v-model="search.crop_id"
                label="text"
                :options= cropNameLists
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button type="button"  variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row mt-5>
                <b-col md="12">
                  <!-- Apex Chart -->
                  <iq-card class="pt-2">
                    <template v-slot:body>
                      <b-row>
                        <b-col lg="6">
                          <b-overlay class="border shadow" :show="loading">
                            <h5 class="text-center p-3 bg-success text-light">{{ $t('sidebar.warehouseSubNav.spaceAvailabilityDashboard')}}</h5>
                            <apexchart class="chart-1 text-center px-5" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
                          </b-overlay>
                        </b-col>
                        <b-col lg="6">
                          <b-overlay class="border shadow" :show="loading">
                            <h5 class="text-center p-3 bg-success text-light">{{ $t('warehouse_service.productStock')}}</h5>
                            <apexchart class="chart-2 text-center px-5" type="pie" :options="productStockLabels" :series="productStockSeries()"></apexchart>
                          </b-overlay>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { spaceAvailabilityDashboardApi } from '../../api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null,
        warehouse_id: null,
        warehouse_type_id: null,
        crop_type_id: null,
        crop_id: null
      },
      organization: [],
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      division_name: '',
      district_name: '',
      upazilla_name: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouseList: [],
      cropNameLists: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      seriesData: [1, 0, 0, 0],
      productStockSeriesData: [1, 0],
      chartOptions: {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: ['Total Space', 'Actual Space', 'Free Space', 'Filled Space'],
        colors: ['#548235', '#0070C0', '#FF0000', '#7030A0'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      loading: false
    }
  },
  computed: {
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    warehouseTypeList: function () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
        // if (this.isWareHouseUser) {
        //   typeList = typeList.filter(item => item.value === this.search.warehouse_type_id)
        // }
        // return typeList
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(document => document.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(document => document.status === 0)
    },
    fiscalList: function () {
      return this.$store.state.warehouse.fiscalYearList.filter(document => document.status === 0)
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    },
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(document => document.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('warehouse_report.division'), class: 'text-left' },
        { label: this.$t('warehouse_report.district'), class: 'text-left' },
        { label: this.$t('warehouse_report.upazilla'), class: 'text-left' },
        { label: this.$t('warehouse_report.warehouse_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.crop_type'), class: 'text-left' },
        { label: this.$t('warehouse_report.crop_name'), class: 'text-left' },
        { label: this.$t('warehouse_report.current_quantity'), class: 'text-right' },
        { label: this.$t('warehouse_report.total_farmer'), class: 'text-right' }
      ]

      let keys = []

      if (this.currentLocale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'crop_type_name_bn' },
          { key: 'crop_name_bn' },
          { key: 'current_quantity' },
          { key: 'total_farmer' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'warehouse_name' },
          { key: 'crop_type_name' },
          { key: 'crop_name' },
          { key: 'current_quantity' },
          { key: 'total_farmer' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: [this.$t('warehouse_service.totalSpace'), this.$t('warehouse_service.actualSpace'), this.$t('warehouse_service.filledSpace'), this.$t('warehouse_service.freeSpace')],
        colors: ['#548235', '#0070C0', '#FF0000', '#7030A0'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.globals.series[opts.seriesIndex]
          },
          offsetX: 500,
          offsetY: 500,
          style: {
            marginLeft: '10px'
          }
        }
      }
    },
    productStockLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: [this.$t('warehouse_service.totalStockQuin'), this.$t('warehouse_service.productStockQuin')],
        colors: ['#0070C0', '#002060'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.globals.series[opts.seriesIndex]
          },
          offsetX: 500,
          offsetY: 500,
          style: {
            marginLeft: '10px'
          }
        }
      }
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
            this.districtList = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameLists = this.getCropNameList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
    if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.districtList = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
         this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_type_id = warehouse.warehouse_type_id
        // this.warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(doc => doc.value === warehouse.warehouse_type_id)
    }
  },
  methods: {
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: '',
        division_name: '',
        district_name: '',
        upazilla_name: '',
        division_name_bn: '',
        district_name_bn: '',
        upazilla_name_bn: ''
      }
    },
    searchData () {
      if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      }
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(warehouseServiceBaseUrl, spaceAvailabilityDashboardApi, this.search).then(response => {
        if (response.success) {
          if (response.data.length) {
            setTimeout(() => {
              this.seriesData = response.data
              this.productStockSeriesData = [response.data[0], response.data[2]]
            }, 1000)
          } else {
            this.seriesData = [0, 0, 0, 0]
            this.productStockSeriesData = [0, 0]
          }
        }
      })
      this.loading = false
    },
    series () {
      return this.seriesData
    },
    productStockSeries () {
      return this.productStockSeriesData
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const activeData = response.filter(document => document.status === 0)
          const dataObject = activeData.filter(document => document.region_id === regionId)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(document => document.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(document => document.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(document => document.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getCropNameList (cropTypeId = null) {
      const cropList = this.$store.state.warehouse.commodityNameList.filter(document => document.status === 0)
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}
.report-name {
  text-align: center;
}
.org-name {
  text-align: center;
}
.org-address {
  text-align: center;
}
.main-title {
  padding: 10px;
}
.project-name {
  text-align: center;
  font-weight: bold;
}
</style>
